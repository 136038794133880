@charset "utf-8";

// ---------------------------------------------------------------
//  mixin
// ---------------------------------------------------------------

// # addQuery
// 
// @desc - ブレイクポイントを挿入します。
// @type Mixin
// @param {String} $point - ブレイクポイントの数値（主にconfigの変数を使用する）
// 
// @example scss - Usage
// .foo {
// 	color: red;
// 
// 	@include addQuery($MQ_TABLET_PORTRAIT) {
// 		color: blue;
// 	}
// }

// @example css - CSS output
// .foo {
// 	color: red;
// }
// @media screen and (min-width: 768px) {
// 	.foo {
// 		color: blue;
// 	}
// }

@mixin addQuery($point) {
	@media screen and (min-width: $point) { @content; }
}
