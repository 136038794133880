@charset "utf-8";
/*!
Theme Name: sfool
Theme URI: http://sfool.net/
Description: 粋で馬鹿なテーマ
Version: 5.0.0
Author: yh
Author URI: http://sfool.net/
*/

/* =========================================================
 style
========================================================= */

// ---------------------------------------------------------------
//  Foundation
// ---------------------------------------------------------------

@import "foundation/_config";
@import "foundation/_normalize";
@import "foundation/_mixin";
@import "foundation/_base";
@import "foundation/_keyframes.scss";
@import "foundation/_webfont.scss";


// ---------------------------------------------------------------
//  Layout
// ---------------------------------------------------------------

@import "layout/_header";
@import "layout/_main";
@import "layout/_footer";


// ---------------------------------------------------------------
//  Layout
// ---------------------------------------------------------------

@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";


// ---------------------------------------------------------------
//  object
// ---------------------------------------------------------------

@import "object/component/_wrap.scss";
@import "object/project/_adsense.scss";
@import "object/project/_font.scss";
@import "object/project/_ico.scss";
@import "object/project/_loading.scss";
@import "object/project/_post.scss";
@import "object/utility/_clearfix.scss";
@import "object/utility/_color.scss";
@import "object/utility/_display.scss";
@import "object/utility/_float.scss";
@import "object/utility/_font.scss";
@import "object/utility/_overflow.scss";
@import "object/utility/_space.scss";
@import "object/utility/_text.scss";


// ---------------------------------------------------------------
//  Page
// ---------------------------------------------------------------

@import "page/_home.scss";
