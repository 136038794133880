@charset "utf-8";

/* =========================================================
 text
========================================================= */

.u-text_left {
	text-align: left;
}
.u-text_center {
	text-align: center;
}
.u-text_right {
	text-align: right;
}
.u-text_center_sm {
	@include addQuery($MQ_MOBILE_LANDSCAPE) {
		text-align: center;
	}
}
.u-text_center_md {
	@include addQuery($MQ_TABLET_PORTRAIT) {
		text-align: center;
	}
}
.u-text_center_lg {
	@include addQuery($MQ_TABLET_LANDSCAPE) {
		text-align: center;
	}
}