@charset "utf-8";

/* =========================================================
 home
========================================================= */


/* home
--------------------------------------------------------- */
.home_header {
	display: flex;
	align-items: center;
	height: 100vh;

	&--anime {
		.header__logo {
			
		}
	}
}
