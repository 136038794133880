@charset "utf-8";

/* =========================================================
 color
========================================================= */

.u-color_text {
	color: $COLOR_TEXT;
}
.u-color_error {
	color: $COLOR_ERROR;
}
.u-color_link {
	color: $COLOR_LINK;
}
.u-color_white {
	color: #fff;
}
