@charset "utf-8";

/* =========================================================
 webfont
========================================================= */


/* Roboto
--------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700);


/* PixelMplus12
--------------------------------------------------------- */
@font-face {
	font-family: 'pixel_mplus_12';
	src: url(assets/font/PixelMplus12-Regular.woff) format("woff");
}
