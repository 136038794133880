@charset "utf-8";

/* =========================================================
 clearfix
========================================================= */

.u-clearfix {
	&:after {
		content: '';
		display: block;
		clear: both;
	}
}
