@charset "utf-8";

/* =========================================================
 font
========================================================= */

.u-font_mincho {
	font-family: $FONT_FAMILY_MINCHO;
}
.u-font_en_sans_serif {
	font-family: $FONT_FAMILY_EN_SANS_SERIF;
}
.u-font_en_serif {
	font-family: $FONT_FAMILY_EN_SERIF;
}
.u-font_ultra_light {
	font-weight: 100;
}
.u-font_light {
	font-weight: 300;
}
.u-font_normal {
	font-weight: normal;
}
.u-font_bold {
	font-weight: bold;
}
.u-font_sm {
	font-size: 0.8em;
}
.u-font_md {
	font-size: 1.2em;
}
.u-font_lg {
	font-size: 1.4em;
}