@charset "utf-8";

/* =========================================================
 base
========================================================= */

* {
	box-sizing: border-box;
}
html {
	color: $COLOR_TEXT;
	font-size: 100%;
	font-family: $FONT_FAMILY_GOTHIC;
	font-feature-settings: 'palt' 1;
	font-kerning: auto;
	line-height: 1.8;
	word-break: break-word;
}
a {
	color: $COLOR_LINK;
	text-decoration: none;

	&:hover,
	&:active {
		text-decoration: underline;
	}

	img {
		transition: all 0.2s ease;

		&:hover {
			opacity: 0.8;
		}
	}
}
p {
	margin-top: 0;
}
img,
canvas,
iframe,
video,
svg {
	max-width: 100%;
	height: auto;
	vertical-align: middle;
}
table {
	border-collapse: collapse;
}
th,
td {
	padding: 0;
}
ol,
ul {
	padding-left: 2em;
}


/* reset
--------------------------------------------------------- */
.ol,
.ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.dl,
.dd {
	margin: 0;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin: 0;
	font-size: 1em;
	font-weight: inherit;
}
.figure {
	margin: 0;
}
.em {
	font-style: normal;
}
.button {
	border: none;
	cursor: pointer;
	background-color: transparent;
	outline: none;
}
