@charset "utf-8";

/* =========================================================
 header
========================================================= */

@keyframes flashing {
	40% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	60% {
		opacity: 1;
	}
}

.header__inner {
	padding-top: 4rem;
	padding-bottom: 4rem;

	@include addQuery($MQ_MOBILE_LANDSCAPE) {
		padding-top: 6rem;
		padding-bottom: 6rem;
	}
}


/* header__logo
--------------------------------------------------------- */
.header__logo {
	margin-bottom: 2rem;
	text-align: center;

	a {
		display: inline-block;
	}

	img {
		width: round(220px * 0.7);

		@include addQuery($MQ_MOBILE_LANDSCAPE) {
			width: 220px;
		}
	}
}


/* header__text
--------------------------------------------------------- */
.header__text {
	overflow: hidden;
	line-height: 1.4;
	text-align: center;
	font-size: 1.1rem;
}

// .header__text__border {
// 	display: inline-block;
// 	position: relative;
// 	padding-right: 1rem;
// 	padding-left: 1rem;

// 	&:before,
// 	&:after {
// 		content: '';
// 		display: block;
// 		position: absolute;
// 		top: 50%;
// 		width: 60px;
// 		border-top: 1px solid #282828;
// 	}

// 	&:before {
// 		right: 100%;
// 	}

// 	&:after {
// 		left: 100%;
// 	}
// }


/* header__arrow
--------------------------------------------------------- */
.header__arrow {
	display: block;
	width: 0;
	height: 0;
	margin-top: 2rem;
	margin-right: auto;
	margin-left: auto;
	border-top: 10px solid $COLOR_TEXT;
	border-right: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid transparent;

	&--anime {
		animation-name: flashing;
		animation-duration: 1.4s;
		animation-timing-function: ease;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
	}
}