@charset "utf-8";

// ---------------------------------------------------------------
//  keyframes
// ---------------------------------------------------------------

// fade
// --------------------
@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
