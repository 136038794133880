@charset "utf-8";

// ---------------------------------------------------------------
//  config
// ---------------------------------------------------------------

// 基本の幅・余白
// --------------------
$BASE_WIDTH_PAGE: 1020px;
$BASE_WIDTH_MAIN: 700px;
$BASE_MARGIN_PAGE_X: 80px;


// レスポンシブのブレークポイント
// --------------------
$MQ_MOBILE_PORTRAIT: 320px;
$MQ_MOBILE_LANDSCAPE: 568px;

$MQ_TABLET_PORTRAIT: 768px;
$MQ_TABLET_LANDSCAPE: 1024px;

$MQ_WIDTH_PAGE: $BASE_WIDTH_PAGE + ($BASE_MARGIN_PAGE_X * 2);
$MQ_WIDTH_MAIN: $BASE_WIDTH_MAIN + ($BASE_MARGIN_PAGE_X * 2);


// フォントファミリー
// --------------------
$FONT_FAMILY_GOTHIC: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Meiryo, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Verdana, sans-serif;
$FONT_FAMILY_MINCHO: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', serif;
$FONT_FAMILY_EN_SANS_SERIF: 'Roboto Condensed', sans-serif;
$FONT_FAMILY_EN_SERIF: 'Times New Roman', serif;


// カラー
// --------------------

// カラーパレット
$PALETTE_BLACK_01: #333;
$PALETTE_GRAY_01: #4d4d4d;
$PALETTE_RED_01: #e26d79;
$PALETTE_BLUE_01: #367ca2;


// テキスト
$COLOR_TEXT: $PALETTE_GRAY_01;
$COLOR_MAIN: $PALETTE_BLACK_01;
$COLOR_NOTE: $PALETTE_RED_01;
$COLOR_ERROR: $PALETTE_RED_01;
$COLOR_LINK: $PALETTE_BLACK_01;
$COLOR_TEXT: $PALETTE_GRAY_01;
