@charset "utf-8";

/* =========================================================
post
========================================================= */

.p-post__header {
	padding-top: 4em;
	padding-bottom: 4em;
	margin-bottom: 6em;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
}
.p-post_title {
	font-size: 2rem;
	line-height: 1.6;

	@include addQuery($MQ_TABLET_PORTRAIT) {
		text-align: center;
	}
}
.p-post_info {
	margin-top: 3em;
	color: #999;
	font-size: 0.8rem;
	font-family: $FONT_FAMILY_EN_SANS_SERIF;
	letter-spacing: 0.05rem;
	text-align: center;
}
.p-post_category {
	margin-left: 1em;

	a {
		color: #999;
	}
}

.p-post__body {
	h3 {
		// position: relative;
		margin-top: 5em;
		// padding-left: 1em;
		font-size: 1.6rem;
		font-weight: normal;

		// &:before {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	content: '';
		// 	width: 1px;
		// 	height: 100%;
		// 	background-color: #eaeaea;
		// }

		// &:after {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 2px;
		// 	content: '';
		// 	width: 1px;
		// 	height: 100%;
		// 	background-color: #eaeaea;
		// }
	}

	h4 {
		position: relative;
		margin-top: 4em;
		margin-bottom: 1em;
		padding-left: 25px;
		font-size: 1.3rem;
		font-weight: normal;
		// border-bottom: 1px solid #eaeaea;

		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 10px;
			height: 10px;
			margin-top: -2px;
			transform: translate(0, -50%) rotate(45deg);
			background-color: $COLOR_TEXT;
		}
	}

	pre {
		overflow: auto;
		padding: 2em;
		margin-top: 2em;
		margin-bottom: 2em;
		color: #fff;
		font-size: 0.8em;
		line-height: 1.4;
		white-space: pre-wrap;
		word-wrap: break-word;
		// border-top: 1px solid #efefef;
		// border-bottom: 1px solid #efefef;
		background-color: #333;
	}

	blockquote {
		position: relative;
		padding-top: 1em;
		padding-right: 1.5em;
		padding-bottom: 1em;
		padding-left: 1.5em;
		margin: 0;
		border-left: 2px solid #efefef;
		border-right: 2px solid #efefef;

		@include addQuery($MQ_MOBILE_LANDSCAPE) {
			padding-right: 3em;
			padding-left: 3em;
		}

		&:before,
		&:after {
			display: block;
			font-size: 3rem;
			line-height: 1;
		}

		&:before {
			content: '“';
		}

		&:after {
			content: '”';
			text-align: right;
		}

		a {
			font-size: 0.8rem;
		}
	}

	.p-post_width_01 {
		margin-top: 3em;
		margin-bottom: 3em;

		@include addQuery($MQ_MOBILE_LANDSCAPE) {
			width: 110%;
			margin-left: -5%;
		}

		@include addQuery($MQ_WIDTH_PAGE) {
			width: $BASE_WIDTH_PAGE;
			margin-left: -160px;
		}
	}

	.p-post_img {
		padding: 1em;
		text-align: center;
		// border: 2px solid #efefef;
		background-color: #efefef;
	}
}


.p-post_comments {
	padding-top: 2em;
	margin-top: 7em;
	font-size: 0.8rem;
	border-top: 1px solid #eaeaea;

	h3 {
		margin-top: 4em;
		font-size: 1rem;
		font-weight: normal;
	}

	.p-post_commets_list {
		padding: 0;
		list-style: none;

		li {
			padding-bottom: 1em;
			margin-bottom: 2em;
			border-bottom: 1px solid #efefef;

			&:first-child {
				padding-top: 2em;
				border-top: 1px solid #efefef;
			}
		}
	}

	.p-post_comment_body {
		display: flex;
		// justify-content: space-between;
	}

	.p-post_comment_img {
		width: 80px;
	}

	.p-post_comment_text {
		flex: 1;
	}

	.p-post_comment_meta {
		color: #999;
		font-size: 0.8rem;
	}

	.p-post_comment-notes {
	}

	.comment-form {
		label {
			display: block;
			margin-bottom: 0.5em;
			font-size: 0.8rem;
		}

		input,
		textarea {
			padding: 0.5em;
		}

		input {
			width: 50%;
		}

		textarea {
			width: 100%;
		}

		.required {
			color: $COLOR_NOTE;
		}

		.form-submit {
			margin-top: 2em;
		}

		.submit {
			display: block;
			width: 100%;
			padding-top: 1.5em;
			padding-right: 2em;
			padding-bottom: 1.5em;
			padding-left: 2em;
			// margin-right: auto;
			// margin-left: auto;
			color: $COLOR_LINK;
			font-size: 0.8rem;
			border: 1px solid #eaeaea;
			background-color: #f6f6f6;
			cursor: pointer;

			@include addQuery($MQ_MOBILE_LANDSCAPE) {
				width: 30%;
			}
		}
	}
}


/* p-post__sns
--------------------------------------------------------- */
.p-post__sns {
	display: flex;
	justify-content: space-between;
	margin-top: 6em;
	margin-bottom: 4em;

	li {
		width: 32%;
		text-align: center;
		transition: all 0.4s ease;

		&:hover {
			opacity: 0.7;
		}
	}

	a {
		overflow: hidden;
		display: block;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
	}
}
.p-post__sns__twitter {
	background-color: #55acee;

	img {
		width: 20px;
	}
}
.p-post__sns__facebook {
	background-color: #3b5998;

	img {
		width: 10px;
	}
}
.p-post__sns__hatena {
	background-color: #008fde;

	img {
		width: 20px;
	}
}


/* p-posts
--------------------------------------------------------- */
.p-posts {
	border-bottom: 1px solid #eaeaea;

	@include addQuery($MQ_TABLET_PORTRAIT) {
		text-align: center;
	}

	li {
		padding-top: 2rem;
		margin-top: 3rem;
		margin-bottom: 3rem;
		border-top: 1px solid #eaeaea;

		// &+li {
		// 	margin-top: 3rem;
		// 	margin-bottom: 3rem;
		// }
	}
}
.p-posts__title {
	position: relative;
	display: block;
	padding-top: 1rem;
	padding-bottom: 1rem;
	font-size: 1.4rem;
	line-height: 1.4;
}
.p-posts__info {
	color: #999;
	font-size: 0.8rem;
	font-family: $FONT_FAMILY_EN_SANS_SERIF;
	letter-spacing: 0.05rem;
}


/* p-post__nav_area
--------------------------------------------------------- */
.p-post__nav_area {
	margin-top: 6em;
	margin-bottom: 3em;
	font-family: $FONT_FAMILY_EN_SANS_SERIF;
	font-weight: bold;
}
.p-post__nav {
	display: table;
	width: 100%;

	li {
		display: table-cell;
	}
}
.p-post__prev {
	a {
		position: relative;
		padding-left: 1rem;

		&:after {
			content: '';
			position: absolute;
			top: 5px;
			left: 2px;
			width: 6px;
			height: 6px;
			border-top: 2px solid #333;
			border-right: 2px solid #333;
			transform: rotate(-130deg);
		}

	}
}
.p-post__next {
	text-align: right;

	a {
		position: relative;
		padding-right: 1rem;

		&:after {
			content: '';
			position: absolute;
			top: 5px;
			right: 1px;
			width: 6px;
			height: 6px;
			border-top: 2px solid #333;
			border-right: 2px solid #333;
			transform: rotate(45deg);
		}

	}
}
