@charset "utf-8";

/* =========================================================
 float
========================================================= */

.u-float_left {
	float: left;
}
.u-float_right {
	float: right;
}
